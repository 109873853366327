import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Navbar from "../components/Navbar";
import "./all.sass";
import "./essential.sass";
import Footer from "./Footer";
import { loadTheme } from "office-ui-fabric-react";
import theme from "./theme";

loadTheme({
  palette: theme
});

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <div
        style={{
          position: "relative",
          paddingBottom: 62
        }}
      >
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href="/img/safari-pinned-tab.svg"
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta
            property="og:description"
            content={data.site.siteMetadata.description}
          />
          <meta property="og:site_name" content="The Technical Review" />
          <meta property="og:url" content="https://thetechnicalreview.com" />
          <meta
            property="og:image"
            content="https://thetechnicalreview.com/img/og-image.jpg"
          />
          <meta
            name="twitter:image"
            content="https://thetechnicalreview.com/img/apple-touch-icon.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@TTRSocial" />
        </Helmet>
        <Navbar />
        <div>{children}</div>
        <Footer />
      </div>
    )}
  />
);

export default TemplateWrapper;
