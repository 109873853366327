import React from "react";
import { Link } from "gatsby";
// import logo from "../img/logo2.jpg";
import Container from "../components/Container";

const Navbar = class extends React.Component {
  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  }

  _openCategories() {
    if (window.innerWidth > 960) return;
    const menu = document.querySelector("#techMenu");
    menu.classList.toggle("navbar-menu");
  }

  _openFeatured() {
    if (window.innerWidth > 960) return;
    const menu = document.querySelector("#featuredMenu");
    menu.classList.toggle("navbar-menu");
  }

  _openAbout() {
    if (window.innerWidth > 960) return;
    const menu = document.querySelector("#aboutMenu");
    menu.classList.toggle("navbar-menu");
  }

  render() {
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <Container>
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="The Technical Review">
              {/* <img
                src={logo}
                alt="The Technical Review"
                title="The Technical Review"
              /> */}
              <h2
                className="is-size-6"
                style={{ paddingLeft: 4, alignSelf: "center", fontWeight: 500 }}
              >
                The Technical Review
              </h2>
            </Link>
            {/* Hamburger menu */}
            <div className="navbar-burger burger" data-target="navMenu">
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
            <div className="navbar-start" />
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <div
                  className="navbar-link link"
                  onClick={() => this._openCategories()}
                >
                  Categories
                </div>
                <div id="techMenu" className="navbar-dropdown  navbar-menu">
                  <Link
                    className="navbar-item"
                    to="/tags/pc"
                    title="PC Articles"
                  >
                    PC
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/phones"
                    title="Phone Articles"
                  >
                    Phones
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/audio"
                    title="Audio Articles"
                  >
                    Audio
                  </Link>
                  <Link className="navbar-item" to="/tags/software">
                    Software
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/gaming"
                    title="Gaming Articles"
                  >
                    Gaming
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/smart-home"
                    title="Smart Home Articles"
                  >
                    Smart Home
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/cryptocurrency"
                    title="Cryptocurrency Articles"
                  >
                    Cryptocurrency
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/lifestyle"
                    title="Lifestyle Articles"
                  >
                    Lifestyle
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div
                  className="navbar-link link"
                  onClick={() => this._openFeatured()}
                >
                  Reviews
                </div>
                <div id="featuredMenu" className="navbar-dropdown  navbar-menu">
                  <Link
                    className="navbar-item"
                    to="/tags/featured"
                    title="Featured Review"
                  >
                    Featured
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/ces-2020"
                    title="CES 2020 Articles"
                  >
                    CES 2020
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/tags/reviews"
                    title="Reviews"
                  >
                    All Reviews
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div
                  className="navbar-link link"
                  onClick={() => this._openAbout()}
                >
                  More
                </div>
                <div id="aboutMenu" className="navbar-dropdown navbar-menu">
                  <Link className="navbar-item" to="/about" title="About">
                    About{" "}
                  </Link>
                  <Link className="navbar-item" to="/contact" title="Contact">
                    Contact{" "}
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/terms"
                    title="Terms of Service"
                  >
                    Terms
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/privacy"
                    title="Privacy Policy"
                  >
                    Privacy
                  </Link>
                  <Link className="navbar-item" title="Events" to="/">
                    Events (Coming Soon)
                  </Link>
                  <Link className="navbar-item" title="Reporters" to="/">
                    Reporters (Coming Soon)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </nav>
    );
  }
};

export default Navbar;
