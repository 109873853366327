export default {
  themePrimary: "#557a95",
  themeLighterAlt: "#f6f9fb",
  themeLighter: "#dde7ee",
  themeLight: "#c2d3df",
  themeTertiary: "#8eaabf",
  themeSecondary: "#6487a1",
  themeDarkAlt: "#4c6d85",
  themeDark: "#405c70",
  themeDarker: "#2f4453",
  neutralLighterAlt: "#faf9f8",
  neutralLighter: "#f3f2f1",
  neutralLight: "#edebe9",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c6c4",
  neutralTertiary: "#a19f9d",
  neutralSecondary: "#605e5c",
  neutralPrimaryAlt: "#3b3a39",
  neutralPrimary: "#323130",
  neutralDark: "#201f1e",
  black: "#000000",
  white: "#ffffff"
};
